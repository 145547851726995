// Given: 6427, '$'
// Returns: '$64.27'
export const currencyFormatter = (value: number, currencySymbol: string) =>
  `${currencySymbol}${(value / 100).toFixed(2)}`;

// Given: 6427, '$'
// Returns: '$64'
export const currencyRoundedFormatter = (
  value: number,
  currencySymbol: string
) => `${currencySymbol}${value / 100}`;

// Given: 64, '$'
// Returns: '$64'
export const currencyLiteralUnitsFormatter = (
  value: number,
  currencySymbol: string
) => `${currencySymbol}${value}`;

// Given: 64.27, '$'
// Returns: '$64'
export const currencyRoundedMainUnitsFormatter = (
  value: number,
  currencySymbol: string
) => `${currencySymbol}${Math.round(value)}`;

// Given: 64.269999, '$'
// Returns: '$64.27'
export const currencyMainUnitsFormatter = (
  value: number,
  currencySymbol: string
) => {
  return `${currencySymbol}${Number(value).toFixed(2)}`;
};

export const currencyFloatToInt = (value: string | undefined | null) =>
  value || value == '0' ? Math.floor(parseFloat(value) * 100) : undefined;
